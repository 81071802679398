const Footer = () => {
  return (
    <div className="text-center sm:py-16 lg:py-24 font-semibold">
      <div className="container mx-auto xl:max-w-9.25xl lg:max-w-6xl sm:max-w-full  xl:px-36 lg:px-8  sm:px-6">
        <div className="md:text-4xl sm:text-3xl sm:mb-8 lg:mb-8 md:w-full md:text-center sm:text-left ">
          Vibe with us on Telegram and Twitter
        </div>

        <div className="md:text-2xl sm:text-lg sm:mb-8 lg:mb-8 md:w-full md:text-center sm:text-left ">
          

          <a
            href="mailto:team@godi.io"
            target="_blank"
            rel="noreferrer"
            className="h-14 rounded-3xl  leading-[3.5rem]  hover:opacity-80 transition-opacity cursor-pointer"
          >
            Email - team@godid.io
          </a>
        </div>
        <div className="lg:w-96 sm:w-auto mx-auto">
          <a
            href="https://t.me/bdidcommunity"
            target="_blank"
            rel="noreferrer"
            className="block no-underline h-14 rounded-3xl  leading-[3.5rem] bg-c1 hover:opacity-80 transition-opacity cursor-pointer text-[0.8125rem] md:text-[1rem]"
          >
            Telegram
          </a>
          <a
            href="https://twitter.com/godid_io"
            target="_blank"
            rel="noreferrer"
            className="block no-underline  h-14 rounded-3xl   mt-8 leading-[3.5rem] bg-c2 hover:opacity-80 transition-opacity cursor-pointer text-[0.8125rem] md:text-[1rem]"
          >
            X / Twitter
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
