import banner from "../../static/1.gif";

const S01 = () => {
  return (
    <div className="bg-black lg:pt-24 sm:pt-11 lg:pb-20 sm:pb-2 w-full">
      <div className="container mx-auto xl:max-w-9.25xl lg:max-w-6xl sm:max-w-full xl:px-36 lg:px-8 sm:px-0">
        <div className="flex justify-between md:flex-row flex-col">
          <div className="sm:mb-8 sm:px-6">
            <h1 className="lg:text-7xl lg:w-3/5 sm:text-5xl md:w-10/12 sm:w-11/12 font-semibold">
              Marketplace for Ordinals DIDs
            </h1>
            <div className="lg:text-xl lg:w-8/12 sm:text-lg md:w-10/12 sm:w-11/12 mt-4">
              Bdid.io, incubated by godid.io, specializes in the registration, transaction, and management of Ordinals Decentralized Identifiers (DIDs)
            </div>
            <div className="lg:text-lg lg:w-8/12 sm:text-sm md:w-10/12 sm:w-11/12 mt-4 font-bold">
              CA：0x5016878159e84dadb05bb04135f3eac339ae201f
            </div>

            <div className="mt-5">
              <span className="bg-c1 rounded-lg inline-block px-2 hover:opacity-80 transition-opacity">Tax:0/0</span>
              <span className="bg-c1 rounded-lg inline-block px-2 ml-3 hover:opacity-80 transition-opacity">Univ3</span>
            </div>
            
          </div>
          <div className="md:px-9 sm:px-0">
            <img
              src={banner}
              alt="banner"
              className="block sm:w-full md:w-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default S01;
