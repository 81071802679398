import styled from "styled-components";


const Header = () => {
  return (
    <HeaderWrap className="w-full bg-c3 fixed">
      <div className="xl:max-w-9.25xl lg:max-w-6xl sm:max-w-full  xl:px-8 lg:px-8 sm:px-6 h-23 mx-auto">
        <div className="flex justify-between items-center h-full ">
          <div className="block lg:w-36 md:w-32 sm:w-28 text-3xl font-extrabold">
            <a href="http://bdid.io/" target="_blank">
            BDID.io
            </a>
          </div>
          <div className="text-center flex leading-9">
            <Link
              href="https://docsend.com/view/gewvz7juwu7dtj67"
              target="_blank"
              className="bg-c1 hover:opacity-80 transition-opacity"
            >
              WhitePaper
            </Link>
            <Link
              href="https://docsend.com/view/scmyn8e5frxtzumf"
              target="_blank"
              className="bg-c1 hover:opacity-80 transition-opacity md:ml-3 ml-[0.5rem]"
            >
              Tokenomics
            </Link>
            <Link
              href="http://bdid.io/"
              target="_blank"
              className="bg-c2 md:ml-3 ml-[0.5rem] hover:opacity-80 transition-opacity"
            >
              Launch App
            </Link>
          </div>
        </div>
      </div>
    </HeaderWrap>
  );
};

const HeaderWrap = styled.div`
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1);
`;

const Link = styled.a`
  display: block;
  text-decoration: none;
  width: 7.8125rem;
  height: 1.75rem;
  font-size: 0.8125rem;
  line-height: 1.75rem;
  letter-spacing: 0.15px;
  border-radius: 1.5rem;
  cursor: pointer;
  @media (min-width: 320px) {
    width: 5rem;
  }
  @media (min-width: 768px) {
    display: block;
    text-decoration: none;
    width: 7.9375rem;
    height: 2.25rem;
    font-size: 1rem;
    line-height: 2.25rem;
    letter-spacing: 0.15px;
    border-radius: 1.5rem;
    cursor: pointer;
  }
`;

export default Header;
