import Header from "./coms/Header";
import S01 from "./coms/Section01";
import S02 from "./coms/Section02";
import S03 from "./coms/Section03";
import Footer from "./coms/Footer";

export default function Index() {
  return (
    <section className="bg-black text-white">
      <Header />
      <div className="h-23"> </div>
      <S01 />
      {/* <S02 /> */}
      <S03 />
      <Footer />
    </section>
  );
}
