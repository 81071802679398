import Icon01 from "../../static/icon-1.svg";
import Icon02 from "../../static/icon-2.svg";
import Icon03 from "../../static/icon-3.svg";
import Icon04 from "../../static/icon-4.svg";

const infoArr = [
  {
    title: "Marketplace",
    desc: "For Collectors",
    svg: Icon01,
  },
  {
    title: "Creator Hub",
    desc: "For Creators",
    svg: Icon02,
  },
  {
    title: "Developer Lab",
    desc: "For Builders",
    svg: Icon04,
  },
  {
    title: "White Label Suite",
    desc: "For Galleries and Agents",
    svg: Icon03,
  },
];

const S03 = () => {
  return (
    <section className="bg-c3 lg:pt-28 lg:pb-36  sm:pt-12 sm:pb-12">
      <div className="container mx-auto xl:max-w-9.25xl lg:max-w-6xl sm:max-w-full xl:px-36 lg:px-8 sm:px-6">
        <div className="lg:text-6xl md:text-3xl sm:text-2xl  md:leading-full leading-9 font-semibold">
        What are Ordinals DIDs?
        </div>
        <div className="lg:text-2xl md:text-xl sm:text-lg lg:leading-8 md:mt-10 mt-4 font-hgs2light mb-3">
        Like but different from ENS, Ordinals DIDs represent those human-readable DIDs inscribed on the Bitcoin blockchain with Ordinals protocol(Anyone can use Ordinals protocol to create DIDs with different suffixes). Currently, there are many Ordinals DIDs, such as .sats, .bitmap, .btc, .x, .bitter, .unisat and etc. Ordinals DIDs are completely decentralized, which means it neither has gatekeepers nor uses smart contracts, and all its records are stored on the Bitcoin blockchain.
        </div>


        <iframe class="w-full aspect-video ..." src="https://www.youtube.com/embed/Ifi-Hg3n3bc"></iframe>


        {/* <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-4 mt-16">
          {infoArr.map((i, index) => (
            <div className={`mr-4  grid-cols-${index + 1}`} key={i.desc}>
              <div className="w-[3.5rem] md:w-[4.625rem]">
                <img src={i.svg} alt="" />
              </div>
              <div className="lg:text-2xl lg:leading-8 font-semibold pt-6 lg:w-52 md:w-52 sm:w-32">
                {i.title}
              </div>
              <div className="text-base mt-2 font-hgs2light">{i.desc}</div>
            </div>
          ))}
        </div> */}
      </div>
    </section>
  );
};

export default S03;
